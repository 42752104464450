<template>
    <main>
        <section id="drop-description">
            <h3 class="drop-title">{{getDrop.title}}</h3>
        <div class="drop-box">
            <figure>
                <div id="schnitt" v-if="getDrop.type == 'video'">
                    <YouTube
                    class="youtube-iframe"
                    :src="'https://www.youtube.com/watch?v='+getDrop.drive"
                    @ready="onReady"
                    ref="youtube"
                    width="100%"
                    height="810"
                    style="marginTop : -55px"
                    :vars="{
                        loop: 1,
                        rel: 0,
                        showinfo: 1,
                        playlist: getDrop.drive,
                        modestbranding: 1,
                    }"
                    />
                </div>
                <figure v-else class="drive-img">
                    <img :src="'https://drive.google.com/uc?export=view&id='+getDrop.drive"/>
                </figure>
            </figure>
            <p>{{getDrop.description}}</p>
        </div>
        <aside>
            <h3>{{getDrop.title}}</h3>
            <div class="join-list">
                <p>Join the waiting list</p>
                <input type="text" placeholder="Your Email" v-model="email"/>
                <span class="invalid" v-if="(email.length > 1 && !email.includes('@')) || (email.length > 1 && !email.includes('.'))"> * invalid Email Address</span>
                <div class="join">
                    <button @click="send">Join Now</button>
                </div>
            </div>
            <div class="creator">
                <h4>Creator</h4>
                <p>{{getDrop.by}}</p>
                <h4>Created</h4>
                <p>{{getDrop.created}}</p>
            </div>
        </aside>
    </section>
    <section id="user-desc" v-if="getUsers">
        <div class="user-info">
            <div class="user-name">
                <figure>
                    <img :src="getImgUrl(getUsers.avatar)" alt="">
                </figure>
                <h4>{{getUsers.name}}</h4>
            </div>
            <div class="user-description">
                <p>{{getUsers.description}}</p>
            </div>
        </div>
    </section>
    <div class="social">
        <a :href="getUsers.twitter" target="_blank">
            <figure>
                <img src="@/assets/icons/twitter.svg" alt="">
            </figure>
        </a>
        <a :href="getUsers.instagram" target="_blank">
            <figure>
                <img src="@/assets/icons/instagram.svg" alt="">
            </figure>
        </a>
    </div>
    </main>
</template>

<script>
import firebase from 'firebase'
import { useToast } from "vue-toastification";
import YouTube from 'vue3-youtube'
export default {
    components: { YouTube },
    data(){
        return {
            email : ""
        }
    },
    methods : {
        getImgUrl(pic) {
            return require('../assets/images/avatar/'+pic)
        },
        send(){
            const toast = useToast();

            
            firebase.auth().createUserWithEmailAndPassword(this.email+String(this.getDrop.id),"1234567").then(()=>{
                this.email = ""
                toast.success("Your email has been successfully registered", {
                    timeout: 4000,
                    position : 'bottom-right'
                });
            }).catch((err)=>{
                if(err.message == "The email address is already in use by another account."){
                        toast.error("This email is already in the waiting list for current art work.", {
                            timeout: 4000,
                            position : 'bottom-right'
                        });
                }
                else{
                    toast.error(err.message, {
                            timeout: 4000,
                            position : 'bottom-right'
                        });
                }
            })
        },
        onReady() {
      this.$refs.youtube.playVideo();
    },
        
    },
    computed : {
        getDrop(){
            const drops = this.$store.getters.getDrops
            const params = this.$route.params.id
            const drop = drops.filter(drop=>Number(drop.id) === Number(params))
            return drop[0]
        },
        getUsers(){
            const users = this.$store.getters.getUsers
            const user = users.filter(user=>user.id == this.getDrop.authorId)
            return user[0]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixins.scss";
    .drive-img{
        width: 100%;
        height: 810px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    #schnitt {
        overflow: hidden;
        height: 810px;
        iframe{
            margin-top: -55px;
        }
    }
    #drop-description{
        display: flex;
        padding: 100px;
        .drop-title{
            display: none;
        }
        .drop-box{
            width: 70%;
            p{
                @include text(16px,24px);
                padding-top: 25px;
                color : "#838181";
            }
        }
        aside{
            width: 30%;
            padding-left: 63px;
            h3{
                @include text(33px,42px);
                color: #000;
            }
            .join-list{
                padding-top: 100px;
                p{
                    @include text(21px,24px);
                    padding-bottom: 15px;
                }
                input{
                    width: 100%;
                    height: 30px;
                    border-bottom: 1px solid #BBC0C6;
                    background: transparent;
                    padding-bottom: 13px;
                    color: #ABA9A9;
                    &::placeholder{
                        @include text(13px,24px);
                        color: #ABA9A9;
                    }
                }
                .invalid{
                    @include text(12px,12px);
                    color : tomato;
                }
                .join{
                    padding-top: 15px;
                    @include flex(flex-end,center);
                    button{
                        height: 22px;
                        width: 56px;
                        background-color: #5D676F;
                        @include text(8px,16px);
                        @include flex(center,center);
                        color: #fff;
                        border-radius: 3px;
                        cursor: pointer;
                    }
                }
            }
            .creator{
                margin-top: 50px;
                background: rgba(152, 152, 152, 0.1);
                border-radius: 3px;
                width: 100%;
                padding: 18px;
                h4{
                    @include text(16px,24px);
                    color: #333333;
                    margin-top: 10px
                }
                p{
                    @include text(16px,24px);
                    color: #999999;
                }
            }
        }
        .details{
            @include text(16px,24px);
            color: #000;
            padding-top: 30px;
        }
    }
    #user-desc{
        padding: 100px;
        .user-info{
            width: 40%;
            .user-name{
                @include flex(flex-start,center);
                figure{
                    width: 82px;
                    height: 82px;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                h4{
                    @include text(29px,24px);
                    color: #000;
                    padding-left: 30px;
                }
            }
            .user-description{
                @include text(16px,24px);
                color: #838181;
                padding-top: 30px;
            }
        }
    }
    .social{
        display: flex;
        justify-content: flex-end;
        padding-right: 120px;
        padding-bottom: 45px;
        a{
            margin-left:23px ;
        }
    }
</style>